import request from "@/utils/request";

export function getPage(params){
  return request({
    url: '/app/mall/meeting/policy/supplier/page',
    method: 'post',
    needToken: false,
    data: params
  })
}

export function getInfo(id) {
  return request({
    url: `/app/mall/meeting/policy/supplier/${id}`,
    method: 'GET'
  })
}