<template>
  <div>
    <Header ref="header"/>

    <div class="container">
      <div class="w w1920">
        <div class="banner">
          <img :src="require('@/assets/images/meeting/main/banner.png')">
        </div>
        <div class="main">
          <div class="choujiang">
            <div class="title">
              <img :src="require('@/assets/images/meeting/main/choujiang_title.png')" class="center-image">
            </div>
            <div class="content">
              <router-link :to="{path:'/marketing/lottery/nineGrid'}">
                <img :src="require('@/assets/images/meeting/main/choujiang_content.png')" class="center-image">
              </router-link>
              <img :src="require('@/assets/images/meeting/main/jiugongge.png')" class="side-image left"/>
              <img :src="require('@/assets/images/meeting/main/hongbao.png')" class="side-image right"/>
            </div>
          </div>
          <div class="changjia">
            <div class="title">
              <img :src="require('@/assets/images/meeting/main/changjia_title.png')" class="center-image">
            </div>
            <div class="content">
              <!-- 30 -->
              <div class="grid-item" v-for="(item, index) in list" v-bind:key="index">
                <router-link :to="{path:'sup',query:{id:item.id}}" target="_blank">
                <div v-if="item.logoImage">
                  <img :src="getCoverImage(item.logoImage)" style="width: 200px;height: 200px;"/>
                </div>
                <div v-else-if="item.logoText" class="text-content">{{item.logoText}}</div>
                <div v-else class="text-content">{{item.supplierName}}</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="pagination">

            <el-pagination
                background
                layout="prev, pager, next"
                :total="params.totalRecord" :current-page.sync="params.currentPage" :page-size="params.pageSize" @current-change="getList">
            </el-pagination>

          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getPage} from "@/api/mall/meeting/policy/supplier"
export default {
  name: "marketingPromotion",
  data() {
    return {
      params: {
        promotionId: '',

        currentPage: 1,
        pageSize: 30,
        totalRecord: 0
      },

      list: []

    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      getPage(this.params).then(res => {
        console.log('=====================')
        console.log(res)
        this.list = res.data.list

        this.params.totalRecord = res.data.pagination.totalRecords
      })
    },

    getCoverImage(url){
      // let json = JSON.parse(images)
      // return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
      return this.staticUrl + '/' + url
    },
  }
}
</script>
<style>
.main {
  /* 设置背景图片 */
  background-image: url('../../assets/images/meeting/main/bg.png');
  background-size: 1920px 2353px;
  background-position: center;
  background-repeat: no-repeat;

  /* 设置背景颜色为红色 */
  background-color: #c02213;

  /* 设置容器的宽度和高度 */
  width: 100%;
  min-height: 2353px; /* 确保背景图片的最小高度 */

  //padding-top: 33px;

  /* 使用 Flexbox 布局 */
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
  justify-content: center;
  align-items: center;

  /* 允许内容超出部分滚动 */
  overflow: auto;

  .choujiang{
    .title{
      width: 1190px;
      height: 194px;
    }
    .content{
      width: 1190px;
      height: 443px;
      margin-top: -60px;

      .side-image {
        position: absolute;
        top: 55%; /* 垂直居中 */
        transform: translateY(-50%); /* 垂直居中 */
        width: 256px; /* 根据需要调整图片宽度 */
        height: auto; /* 保持图片比例 */
      }
    }
  }
  .changjia{
    .title{
      width: 1190px;
      height: 194px;
    }
    .content {
      width: 1190px;
      height: auto;
      display: grid;
      grid-template-columns: repeat(5, 200px);
      grid-template-rows: repeat(6, 200px);
      gap: 46px; /* 间隔，可根据需要调整 */

      .grid-item {
        width: 200px;
        height: 200px;
        background-color: white; /* 可根据需要调整颜色 */
        border: 1px solid #ccc; /* 可根据需要调整边框 */
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .text-content {
          font-size: 20px; /* 设置字体大小 */
          text-align: center; /* 居中文本 */
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100%;
  margin: 30px 0 20px 0; /* 添加一些上下间距 */

}

.active{
  background-color: red !important;
}

.side-image.left {
  left: -120px; /* 根据需要调整位置 */
}

.side-image.right {
  right: -120px; /* 根据需要调整位置 */
}


</style>